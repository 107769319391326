import { StaticImage } from 'gatsby-plugin-image'
import React, { useMemo } from 'react'
import { theme } from 'twin.macro'
import useBreakpoint from 'use-breakpoint'
import { ReactComponent as IconDrawings } from '../../images/product/drawings.svg'
import { ReactComponent as IconVisualizations } from '../../images/product/visualizations.svg'
import { ReactComponent as IconEstimate } from '../../images/product/estimate.svg'
import { ReactComponent as IconDeals } from '../../images/product/deals.svg'

const mdMinWidth = parseInt(theme('screens.md'), 10)
const BREAKPOINTS = { md: mdMinWidth }

function Product({ className, heading, icons, afterIcons, ...props }) {
  const { breakpoint } = useBreakpoint(BREAKPOINTS)
  const isMd = useMemo(() => breakpoint === 'md', [breakpoint])

  return (
    <section className="section section-product mb-20 md:mb-48">
      <div className="container">
        <div className="md:flex flex-row flex-nowrap items-stretch">
          <div className="heading relative md:pl-[4.5rem] md:w-[18.875rem] flex-shrink-0 flex-grow-0 md:border-r-2 md:border-[rgba(0,0,0,.5)]">
            <StaticImage
              className="!hidden md:!block !absolute left-0 top-0 mt-1"
              src="../../images/product-decor.png"
              width={48}
              height={48}
              role="presentation"
              placeholder="none"
              alt=""
            />
            {heading && (
              <h2
                className="text-center md:text-left mb-16"
                dangerouslySetInnerHTML={{ __html: heading }}
              />
            )}
          </div>

          <div className="ml-auto">
            {icons && (
              <ul className="product-contains flex flex-row flex-wrap items-stretch">
                <li className="w-1/2 lg:w-1/4 px-2 lg:px-1 mb-12 text-center">
                  <figure>
                    <IconDrawings className="inline-block fill-current w-20 h-20 md:w-16 md:h-16" />
                    <figcaption className="mt-4 md:font-bold" dangerouslySetInnerHTML={{__html: icons.drawings }} />
                  </figure>
                </li>

                <li className="w-1/2 lg:w-1/4 px-2 lg:px-1 mb-12 text-center">
                  <figure>
                    <IconVisualizations className="inline-block fill-current w-20 h-20 md:w-16 md:h-16" />
                    <figcaption className="mt-4 md:font-bold" dangerouslySetInnerHTML={{__html: icons.visualizations }} />
                  </figure>
                </li>

                <li className="w-1/2 lg:w-1/4 px-2 lg:px-1 mb-12 text-center">
                  <figure>
                    <IconEstimate className="inline-block fill-current w-20 h-20 md:w-16 md:h-16" />
                    <figcaption className="mt-4 md:font-bold" dangerouslySetInnerHTML={{__html: icons.estimate }} />
                  </figure>
                </li>

                <li className="w-1/2 lg:w-1/4 px-2 lg:px-1 mb-12 text-center">
                  <figure>
                    <IconDeals className="inline-block fill-current w-20 h-20 md:w-16 md:h-16" />
                    <figcaption className="mt-4 md:font-bold" dangerouslySetInnerHTML={{__html: icons.deals }} />
                  </figure>
                </li>
              </ul>
            )}

            {afterIcons?.remark?.html && (
              <div
                className="text-center md:text-lg"
                dangerouslySetInnerHTML={{
                  __html: afterIcons.remark.html,
                }}></div>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Product
