import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { AnimatePresence, motion } from 'framer-motion'

const motionVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
  },
}

function Overlay({
  className,
  visible = false,
  onClose,
  onOpen,
  children,
  ...props
}) {
  const [visibilityState, setVisibility] = useState(visible)
  const initial = useRef(true)

  /**
   * Handle prop change
   */
  useEffect(() => {
    setVisibility(visible)
  }, [visible])

  useEffect(() => {
    if (initial.current) {
      return
    }

    if (visibilityState) {
      if (onOpen && onOpen.call) {
        onOpen()
      }
    } else if (onClose && onClose.call) {
      onClose()
    }
  }, [visibilityState])

  const backdropClickHandler = useCallback((e) => {
    setVisibility(false)
    e.stopPropagation()
  }, [])

  useEffect(() => {
    if (visible) {
      setVisibility(false)
    }
  }, [])

  useEffect(() => {
    initial.current = false
  }, [])

  return (
    <AnimatePresence>
      {visibilityState ? (
        <motion.div
          key="modal-overlay"
          className="overlay fixed z-[1000] inset-0 h-screen overflow-hidden bg-black bg-opacity-70 flex flex-row justify-center items-center"
          variants={motionVariants}
          initial="hidden"
          animate="visible"
          exit="hidden"
          onClick={backdropClickHandler}>
          <div
            className="flex flex-row justify-center items-center h-full overflow-y-auto"
            onClick={(e) => e.stopPropagation()}>
            {children}
          </div>
        </motion.div>
      ) : null}
    </AnimatePresence>
  )
}

export default Overlay
