import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/scrollbar'
import { Scrollbar } from 'swiper'
import tw, { styled, theme } from 'twin.macro'
import classNames from 'classnames'
import { useBreakpoints } from '../utils'

function SwiperSliderBase({ className, children, ...props }) {
  const { minWidth } = useBreakpoints()

  const md = parseInt(theme('screens.md'), 10)
  const dragSize = minWidth >= 768 ? 170 : 42

  return (
    <Swiper
      className={classNames(className)}
      modules={[Scrollbar]}
      {...props}
      slidesPerView={1}
      spaceBetween={24}
      breakpoints={{
        [md]: {
          slidesPerView: 2,
        },
      }}
      scrollbar={{ draggable: true, dragSize }}>
      {children}
    </Swiper>
  )
}

const SwiperSlider = styled(SwiperSliderBase)`
  .swiper-wrapper {
  }

  .swiper-slide {
  }

  ${tw`mr-20`};

  overflow: visible !important;

  &.swiper-horizontal {
    ${tw`pb-10 md:pb-20`}

    > .swiper-scrollbar {
      ${tw`bg-black`}
      width: calc(100% + ${theme('spacing.20')});
      height: 1px;
      left: 0;
      bottom: 0;
      margin-bottom: 1px;
      overflow: visible !important;
      border-radius: 0;

      .swiper-scrollbar-drag {
        ${tw`bg-black`}
        height: 4px;
        margin-top: -1px;
      }
    }
  }
`

export default function Slider({ slides, children, ...props }) {
  return (
    <SwiperSlider>
      {slides.map((slide, idx) => (
        <SwiperSlide key={slide.id || idx}>{slide.content}</SwiperSlide>
      ))}
    </SwiperSlider>
  )
}
