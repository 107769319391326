import { useWindowHeight } from '@react-hook/window-size'
import classNames from 'classnames'
import { StaticImage } from 'gatsby-plugin-image'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useBreakpoints } from '../utils'
import Button from './Button'
import { LazyMotion, domAnimation, m, useViewportScroll } from 'framer-motion'
import { animate } from 'popmotion'
import { ReactComponent as ArrowTopIcon } from '../images/arrow-top.svg'

const motionVariants = {
  visible: {
    y: 0,
    opacity: 1,
  },
  hidden: {
    y: '30px',
    opacity: 0,
  },
}

function GoToTop({ className }) {
  const { minWidth } = useBreakpoints()
  const { scrollY } = useViewportScroll()
  const winHeight = useWindowHeight()
  const activeBodyScrollAnimation = useRef(null)

  // spring.
  const [variant, setVariant] = useState('hidden')

  useEffect(() => {
    return scrollY.onChange((value) => {
      if (value >= winHeight) {
        setVariant('visible')
      } else {
        setVariant('hidden')
      }
    })
  }, [scrollY])

  const buttonClickHandler = useCallback(() => {
    if (activeBodyScrollAnimation.current) {
      activeBodyScrollAnimation.current.stop()
    }

    activeBodyScrollAnimation.current = animate({
      from: scrollY.get(),
      to: 0,
      velocity: scrollY.getVelocity(),
      onUpdate: (top) => {
        window.scrollTo({ top })
      },
    })
  }, [scrollY])

  return (
    <LazyMotion features={domAnimation}>
      <m.div
        className={classNames(className)}
        variants={motionVariants}
        animate={variant}>
        <Button
          onClick={buttonClickHandler}
          className="w-12 h-12 md:w-[4.5rem] md:h-[4.5rem] px-2 md:px-4 inline-flex flex-row justify-center items-center">
          <ArrowTopIcon className="fill-current stroke-current w-3 h-3 md:w-5 md:h-5" />
        </Button>
      </m.div>
    </LazyMotion>
  )
}

export default GoToTop
