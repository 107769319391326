import React from 'react'
import classNames from 'classnames'
import tw, { styled, theme } from 'twin.macro'

function Newsletter({ className, formHtml }) {
  return (
    <section
      className={classNames('section section-mailchimp mb-20', className)}>
      <div
        className="container"
        dangerouslySetInnerHTML={{ __html: formHtml }}
      />
    </section>
  )
}

const NewsletterStyled = styled(Newsletter)`
  #mc_embed_signup {
    font-family: inherit !important;
    label {
      ${tw`mb-6`}
    }
    
    .email {
      ${tw`inline-block border-b border-t-0 border-l-0 border-r-0 border-black py-2 px-0 !h-auto rounded-none h-[50px] mr-4`}
    }
    
    .button {
      height: auto !important;
      ${tw`bg-black border-2! rounded-none border-solid border-black text-white hover:text-black focus:text-black leading-[1.1] whitespace-nowrap uppercase font-bold px-6 py-4 transition-colors duration-300 focus:outline-none`}
      ${tw`hover:bg-transparent focus:bg-transparent hover:text-black focus:text-black`}
    }
  }
  
`

export default NewsletterStyled
