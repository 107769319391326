import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React from 'react'
import ButtonInverted from '../../components/ButtonInverted'
import tw, { styled } from 'twin.macro'
import classNames from 'classnames'

const StyledGatsbyImage = styled(GatsbyImage)`
  position: static !important;
  ${tw`select-none`}

  > div:first-of-type {
    display: none;
  }
`

function CTA({ className, heading, bgImage, ctaOnClick = () => {}, ...props }) {
  return (
    <section className={classNames('section section-cta', className)}>
      <div className="relative py-16 md:py-28 text-center overflow-hidden">
        {bgImage && (
          <StyledGatsbyImage
            image={getImage(bgImage)}
            alt="Background"
            role="presentation"
          />
        )}
        <div className="absolute top-0 left-0 z-15 w-full h-full flex flex-row justify-center items-center hover:scale-105 transition-transform duration-300">
          <ButtonInverted
            onClick={ctaOnClick}
            as="a"
            className="!whitespace-normal max-w-[calc(100%-4rem)] stretched-link">
            {heading}
          </ButtonInverted>
        </div>
      </div>
    </section>
  )
}

export default CTA
