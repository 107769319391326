import classNames from 'classnames'
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import Button from '../../components/Button'
import tw, { styled } from 'twin.macro'

const StepList = styled.ol`
  counter-reset: howtosteps;
`

const StepListItem = styled.li`
  counter-increment: howtosteps;

  &:first-of-type {
    ${tw`-mt-6`}
  }
`

function HowItWorks({
  className,
  heading,
  buttonText,
  steps,
  ctaOnClick = () => {},
  ...props
}) {
  return (
    <section
      className={classNames(
        'section section-howitworks mb-20 lg:mb-48',
        className
      )}>
      <div className="container">
        <div className="text-center mb-16">
          <div className="inline-block heading relative lg:pl-[4.5rem] lg:pt-4">
            <StaticImage
              className="!hidden lg:!block !absolute left-0 bottom-0"
              src="../../images/how-decor.png"
              width={48}
              height={48}
              role="presentation"
              placeholder="none"
              alt=""
            />
            {heading && (
              <h2
                className="text-center mb-0"
                dangerouslySetInnerHTML={{ __html: heading }}
              />
            )}
          </div>
        </div>

        <div className="-mx-4 bg-[#EDEDED] lg:bg-transparent lg:bg-initial py-px text-center">
          <StepList className="steps px-4 lg:flex lg:-mx-3 flex-row flex-nowrap">
            {steps &&
              Object.entries(steps).map(([stepName, data]) => (
                <StepListItem
                  key={stepName}
                  className="mb-16 lg:px-3 lg:w-1/4 lg:flex flex-col-reverse items-stretch">
                  <h3 className="flex flex-col items-center font-bold font-sans lg:mt-4 lg:font-normal lg:whitespace-nowrap lg:flex-row lg:items-center before:flex-grow-0 before:flex-shrink-0 before:flex before:flex-row before:justify-center before:items-center before:w-12 before:h-12 lg:before:w-8 lg:before:h-8 before:bg-black before:text-white before:text-2xl lg:before:text-base before:leading-none before:font-sans before:font-bold before:rounded-full before:content-[counter(howtosteps)] lg:before:content-[counter(howtosteps)] before:mb-2 lg:before:mb-0 lg:before:mr-2">
                    {data.name}
                  </h3>

                  {data.image && (
                    <GatsbyImage image={getImage(data.image)} alt={data.name} />
                  )}
                </StepListItem>
              ))}
          </StepList>

          <Button className="align-bottom -mb-6" onClick={ctaOnClick}>
            {buttonText}
          </Button>
        </div>
      </div>
    </section>
  )
}

export default HowItWorks
