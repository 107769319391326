import classNames from 'classnames'
import React from 'react'
import ReactPlayer from 'react-player'
import styled from 'styled-components'
import tw from 'twin.macro'
import { useBreakpoints } from '../../utils'

function Video({ className, cover, videoUrl }) {
  const { minWidth } = useBreakpoints()

  const light = cover.publicURL || true

  const height = minWidth > 768 ? '75vh' : '50vh'

  return (
    <section
      className={classNames('section section-video transition ', className)}>
      <ReactPlayer
        url={videoUrl}
        light={light}
        width="100%"
        height={height}
        playing={true}
      />
    </section>
  )
}

const VideoStyled = styled(Video)`
  .react-player {
    &__shadow {
      ${tw`transition-transform duration-300`}
      ${tw`!bg-black !rounded-none`}
      ${tw`md:!w-20 md:!h-20`}
    }

    &__preview {
      &:hover {
        .react-player__shadow {
          ${tw`scale-110`}
        }
      }
    }
  }
`

export default VideoStyled
