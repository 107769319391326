import classNames from 'classnames'
import React from 'react'
import 'swiper/css'
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image'
import Slider from '../../components/Slider'
import { styled } from 'twin.macro'

function Portfolio({ className, heading, slider, ...props }) {
  return (
    <section
      className={classNames('section section-portfolio mb-20', className)}>
      <div className="container overflow-hidden max-w-full">
        <div className="heading relative md:pl-[4.5rem] md:pt-4 md:mb-16">
          <StaticImage
            className="!hidden md:!block !absolute left-0 bottom-0"
            src="../../images/portfolio-decor.png"
            width={48}
            height={48}
            role="presentation"
            placeholder="none"
            alt=""
          />
          {heading && <h2 dangerouslySetInnerHTML={{ __html: heading }} />}
        </div>
      </div>

      {slider.length && (
        <div className="overflow-hidden max-w-full">
          <div className="container">
            <Slider
              slides={slider.map((slide) => ({
                id: slide.id,
                content: <GatsbyImage image={getImage(slide)} imgClassName="select-none" alt="" />,
              }))}
            />
          </div>
        </div>
      )}
    </section>
  )
}

const PortfolioStyled = styled(Portfolio)``

export default PortfolioStyled
