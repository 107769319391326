import React from 'react'
import classNames from 'classnames'
import { styled } from 'twin.macro'
import { StaticImage } from 'gatsby-plugin-image'

const ContentDiv = styled.div`
  > p {
    color: rgba(0, 0, 0, 0.7);
  }
`

function ForWhom({ className, heading, ...props }) {
  return (
    <section className={classNames('section section-what-is', className)}>
      <div className="container">
        <div className="heading relative md:pl-[4.5rem] md:pt-4">
          <StaticImage
            className="!hidden md:!block !absolute left-0 bottom-0"
            src="../../images/whom-decor.png"
            width={48}
            height={48}
            role="presentation"
            placeholder="none"
            alt=""
          />
          {heading && <h2 dangerouslySetInnerHTML={{ __html: heading }} />}
        </div>

        <div className="flex flex-row flex-wrap items-stretch md:mt-10">
          {['contentLeft', 'contentRight'].map(
            (prop) =>
              props[prop].remark?.html && (
                <ContentDiv
                  key={prop}
                  className={classNames('w-full mb-12 md:mb-0 md:w-1/2', {
                    'lg:w-[42%] md:pr-4 md:pl-[4.5rem]': prop === 'contentLeft',
                    'lg:w-[58%] md:pl-4 xl:pr-24': prop === 'contentRight',
                  })}
                  dangerouslySetInnerHTML={{ __html: props[prop].remark.html }}
                />
              )
          )}
        </div>
      </div>
    </section>
  )
}

const ForWhomStyled = styled(ForWhom)``

export default ForWhomStyled
