import classNames from 'classnames'
import React, { useRef, useCallback, useState } from 'react'

function FileUploadButton({ className, children, onChange, ...props }) {
  const buttonRef = useRef()
  const inputRef = useRef()
  const [selectedFilename, setSelectedFilename] = useState('')

  const clickHandler = useCallback(() => {
    if (!inputRef.current) {
      return
    }
    inputRef.current.click()
  }, [])

  const fileUploadHandler = useCallback(
    (...args) => {
      const [e] = args

      if (inputRef.current && inputRef.current.files?.length) {
        setSelectedFilename(inputRef.current.files[0]?.name)
      } else {
        setSelectedFilename('')
      }

      if (onChange && onChange.call) {
        onChange(...args)
      }
    },
    [onChange]
  )

  return (
    <>
      <input
        ref={inputRef}
        type="file"
        onChange={fileUploadHandler}
        {...props}
        className="opacity-0 w-0 h-0 absolute overflow-hidden"
      />
      <button
        type="button"
        ref={buttonRef}
        onClick={clickHandler}
        className={classNames(
          'leading-[1.1] border-2 border-black text-black hover:bg-black hover:text-white whitespace-nowrap font-bold px-6 py-4 transition-colors duration-300 focus:outline-none',
          className
        )}>
        {selectedFilename ? selectedFilename : children}
      </button>
    </>
  )
}

export default FileUploadButton
