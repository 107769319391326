import React from 'react'
import classNames from 'classnames'
import { styled } from 'twin.macro'
import { StaticImage } from 'gatsby-plugin-image'

const ContentDiv = styled.div`
  > p {
    line-height: inherit;
  }
`

function WhatIs({ className, heading, content, ...props }) {
  return (
    <section className={classNames('section section-what-is', className)}>
      <div className="container">
        <div className="md:flex flex-row flex-nowrap items-stretch">
          <div className="heading relative md:pl-[4.5rem] md:w-[22.75rem] flex-shrink-0 flex-grow-0 md:border-r-2 md:border-[rgba(0,0,0,.5)] md:mr-[7.5rem]">
            <StaticImage
              className="!hidden md:!block !absolute left-0 top-0 mt-2"
              src="../../images/what-decor.png"
              width={48}
              height={48}
              role="presentation"
              placeholder="none"
              alt=""
            />
            {heading && (
              <h2
                className="md:leading-[1.32]"
                dangerouslySetInnerHTML={{ __html: heading }}></h2>
            )}
          </div>
          {content.remark?.html && (
            <ContentDiv
              className="md:text-lg md:leading-[1.83] lg:leading-[2.0625rem]"
              dangerouslySetInnerHTML={{ __html: content.remark.html }}
            />
          )}
        </div>
      </div>
    </section>
  )
}

const WhatIsStyled = styled(WhatIs)``

export default WhatIsStyled
