import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Formik, Field, Form } from 'formik'
import Button from './Button'
import FileUploadButton from './FileUploadButton'
import { StaticImage } from 'gatsby-plugin-image'
import { ReactComponent as PaperClipIcon } from '../images/attached.svg'
import { fromBlob } from 'image-resize-compress'

function PlanUploadField({ field, form, placeholder: children, ...props }) {
  return (
    <FileUploadButton {...field} {...props}>
      {children}
    </FileUploadButton>
  )
}

async function processImage(blob) {
  const { width: imgWidth, height: imgHeight } = await new Promise(
    (resolve) => {
      const Img = new Image()

      var objectUrl = URL.createObjectURL(blob)
      Img.onload = () => {}
      Img.src = objectUrl
      Img.onload = function () {
        const { width, height } = Img
        URL.revokeObjectURL(objectUrl)
        resolve({ width, height })
      }
    }
  )

  const width = imgWidth >= imgHeight ? 1280 : 'auto'
  const height = imgHeight > imgWidth ? 1280 : 'auto'

  return fromBlob(blob, 75, width, height, 'jpeg')
}

function EnqueryForm({ onBackButtonClick }) {
  const formRef = useRef()

  const handleSubmit = useCallback(async (values, { resetForm, setStatus }) => {
    const formData = new FormData(formRef.current)

    const userImage = formData.get('plan')

    const optimizedImage = new File(
      [await processImage(userImage)],
      userImage.name,
      {
        type: 'image/jpeg',
      }
    )
    formData.set('plan', optimizedImage)

    try {
      const response = await fetch('/', {
        method: 'POST',
        body: formData,
      }).then((res) => {
        if (!res.ok) {
          throw res.statusText
        }

        return res
      })

      resetForm()
      setStatus('success')
      if (window.dataLayer) {
        window.dataLayer.push({ event: 'formSubmission' })
      }
    } catch (e) {
      setStatus('errored')
      console.warn('form submit error: ', e.toString())
    }
  }, [])

  const planUploadPlaceholder = (
    <>
      Прикачи скица на жилището{' '}
      <PaperClipIcon className="w-5 h-5 fill-current inline-block" /> *
    </>
  )

  return (
    <Formik
      status=""
      initialValues={{
        name: '',
        email: '',
        phone: '',
        notes: '',
        plan: '',
      }}
      onSubmit={handleSubmit}>
      {({ status, isSubmitting }) => {
        return status !== 'success' ? (
          <Form ref={formRef} data-netlify="true" netlify-honeypot="bot-field">
            {status === 'errored' && (
              <p className="text-red-600">
                Възникна проблем с вашата заявка. Моля опитайте отново.
              </p>
            )}
            <p className="text-xl font-bold md:text-2xl">
              Попълнете Вашите коoрдинати, и добавете скица на жилището и ще Ви
              изпратим персонализирана оферта!
            </p>

            <input type="hidden" name="form-name" value="form 1" />

            <div className="mb-6">
              <Field
                name="name"
                className="block border-b border-black w-full py-2 px-0"
                placeholder="Име*"
                required
              />
            </div>
            <div className="mb-6">
              <Field
                name="email"
                type="email"
                className="block border-b border-black w-full py-2 px-0"
                placeholder="Емейл*"
                required
              />
            </div>
            <div className="mb-6">
              <Field
                name="phone"
                type="phone"
                className="block border-b border-black w-full py-2 px-0"
                placeholder="Телефон*"
                required
              />
            </div>

            <div className="mt-8 mb-6">
              <Field
                name="plan"
                component={PlanUploadField}
                placeholder={planUploadPlaceholder}
                accept="image/*"
                required
              />
            </div>

            <div className="mb-6">
              <Field
                as="textarea"
                name="notes"
                className="block border-b border-black w-full py-2 px-0 max-h-40"
                placeholder="Допълнителни бележки"
              />
            </div>

            <p className="text-sm leading-tight text-[#08060699] md:text-xs mb-2 md:mb-8">
              След като изпратите запитване ще го разгледаме и<br /> ще Ви
              върнем отговор в рамките на 24 часа.
            </p>

            <div className="md:mt-12">
              <Button type="submit" loading={isSubmitting}>
                Изпрати запитване
              </Button>
            </div>
          </Form>
        ) : (
          <div className="text-center">
            <StaticImage
              src="../images/confetti.png"
              className="!mx-auto"
              placeholder="none"
              backgroundColor="transparent"
            />
            <p className="text-xl font-bold md:text-2xl">
              Запитването Ви е изпратено, ще го прегледаме и ще Ви изпратим
              оферта до 24 часа!
            </p>

            {isSubmitting ? (
              <div className="text-red-600">submitting</div>
            ) : null}

            {onBackButtonClick && (
              <Button onClick={onBackButtonClick}>Върнете се в Началото</Button>
            )}
          </div>
        )
      }}
    </Formik>
  )
}

export default EnqueryForm
