import classNames from 'classnames'
import React from 'react'

export default function ButtonInverted({
  className,
  children,
  as: Component = 'button',
  onClick = () => {},
  ...props
}) {
  const rootProps = {}

  if (Component === 'a') {
    Object.assign(rootProps, {
      href: props.href || '#',
    })

    if (rootProps.href === '#') {
      Object.assign(rootProps, {
        role: 'button',
      })
    }
  }

  return (
    <Component
      {...rootProps}
      className={classNames(
        className,
        'bg-white text-black leading-[1.1] whitespace-nowrap uppercase font-bold px-6 py-4 border-0'
      )}
      onClick={onClick}
      >
      {children}
    </Component>
  )
}
