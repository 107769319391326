import { theme } from 'twin.macro'
import useBreakpoint from 'use-breakpoint'

// console.log('screens: ', theme('screens'))

const BREAKPOINTS = {
  xs: 0,
  ...Object.fromEntries(
    Object.entries(theme('screens')).map(([bp, minWidth]) => [
      bp,
      typeof minWidth === 'string' ? parseInt(minWidth, 10) : minWidth.min,
    ])
  ),
}

export function useBreakpoints() {
  return useBreakpoint(BREAKPOINTS, 'xs')
}
