import classNames from 'classnames'
import React from 'react'
import { ReactComponent as SpinnerIcon } from '../images/spinner.svg'

export default function Button({ className, children, loading, ...props }) {
  return (
    <button
      className={classNames(
        className,
        'bg-black border-2 border-black text-white hover:text-black focus:text-black leading-[1.1] whitespace-nowrap uppercase font-bold px-6 py-4 transition-colors duration-300 focus:outline-none',
        {
          'hover:bg-transparent focus:bg-transparent hover:text-black focus:text-black':
            !loading,
          relative: loading,
        }
      )}
      {...props}>
      <>
        <span
          className={classNames({
            'opacity-0': loading,
          })}>
          {children}
        </span>

        {loading ? (
          <SpinnerIcon className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 fill-current w-7 h-7" />
        ) : null}
      </>
    </button>
  )
}
