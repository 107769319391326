import classNames from 'classnames'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion'
import tw, { styled, theme } from 'twin.macro'
import PlusIcon from '../../images/plus.svg'
import MinusIcon from '../../images/minus.svg'

const AccordionItemButtonStyled = styled(AccordionItemButton)`
  transition: all 300ms ease-in-out;
  background-color: #999;
  background-image: url(${PlusIcon});
  background-repeat: no-repeat;
  background-size: 2rem;
  background-position: calc(100% - 0.5rem) 50%;
  
  ${tw`text-white text-sm md:text-base`}
  ${tw`font-bold font-sans`}
  ${tw`pl-4 pr-12 py-2 md:pl-7 md:pr-16 md:py-5`}

  @media (min-width: ${theme('screens.md')}) {
    background-size: auto;
    background-position: calc(100% - 1rem) 50%;
  }

  
  &:hover, &:focus {
    background-color: #666;
  }

  &[aria-expanded='true'] {
    ${tw`bg-black text-white`}
    background-image: url(${MinusIcon});
  }
`

const AccordionItemStyled = styled(AccordionItem)`
  & + & {
    ${tw`mt-2 md:mt-4`}
  }
`

function FAQ({ className, heading, qna, afterQna, ...props }) {
  return (
    <section className={classNames(className, 'section section-faq')}>
      <div className="container">
        <div className="heading relative md:pl-[4.5rem] md:pt-4 md:mb-16">
          <StaticImage
            className="!hidden md:!block !absolute left-0 bottom-0"
            src="../../images/faq-decor.png"
            width={48}
            height={48}
            role="presentation"
            placeholder="none"
            alt=""
          />
          {heading && <h2 dangerouslySetInnerHTML={{ __html: heading }} />}
        </div>

        {qna.length && (
          <Accordion allowZeroExpanded className="mb-12 md:mx-20 md:mb-20">
            {qna.map(({ question, answer }) =>
              answer ? (
                <AccordionItemStyled key={question}>
                  <AccordionItemHeading>
                    <AccordionItemButtonStyled>
                      {question}
                    </AccordionItemButtonStyled>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <div
                      className="px-1 pt-3 pb-1 leading-relaxed md:px-6 md:py-8"
                      dangerouslySetInnerHTML={{
                        __html: answer?.remark?.html,
                      }}></div>
                  </AccordionItemPanel>
                </AccordionItemStyled>
              ) : null
            )}
          </Accordion>
        )}

        {afterQna?.remark?.html && (
          <div
            className="text-center md:text-lg after-qna"
            dangerouslySetInnerHTML={{ __html: afterQna.remark.html }}
          />
        )}
      </div>
    </section>
  )
}

const FAQStyled = styled(FAQ)`
  .after-qna a {
    ${tw`font-bold`}
    ${tw`hover:underline focus:underline`}
  }
`

export default FAQStyled
