import React, { useMemo, useState } from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, getImage, getSrc, StaticImage } from 'gatsby-plugin-image'
import '../style/main.css'
import '@fontsource/montserrat/400.css'
import '@fontsource/montserrat/700.css'
import '@fontsource/playfair-display/400.css'
import '@fontsource/playfair-display/700.css'
import '@fontsource/playfair-display/900.css'
import classNames from 'classnames'
import WhatIsSection from './sections/WhatIs'
import ForWhomSection from './sections/ForWhom'
import VisualizationsSection from './sections/Visualizations'
import Button from '../components/Button'
import HowItWorksSection from './sections/HowItWorks'
import ProductSection from './sections/Product'
import PortfolioSection from './sections/Portfolio'
import AwardsSection from './sections/Awards'
import CTASection from './sections/CTA'
import FAQSection from './sections/FAQ'
import { styled, css, theme } from 'twin.macro'
import VideoSection from './sections/Video'
import GoToTop from '../components/GoToTop'
import Overlay from '../components/Overlay'
import { motion } from 'framer-motion'
import EnqueryForm from '../components/EnquieryForm'
import { ReactComponent as LogoColor } from '../images/All-in-studio-color.svg'
import { ReactComponent as LogoWhite } from '../images/All-in-studio-white.svg'
import { ReactComponent as CloseIcon } from '../images/close.svg'
import { ReactComponent as FacebookIcon } from '../images/social/facebook.svg'
import { ReactComponent as InstagramIcon } from '../images/social/instagram.svg'
import { ReactComponent as EnquiryIcon } from '../images/enquiry.svg'
import { Helmet } from 'react-helmet'
import { useBreakpoints } from '../utils'
import Newsletter from './sections/Newsletter'

function TenYearsDesign({ className }) {
  return (
    <div lang="en" className={classNames(className, '')}>
      10 Years of design service
    </div>
  )
}

function Header({ className, ...props }) {
  return (
    <header className={classNames('md:relative md:z-[200]', className)}>
      <div className="container flex flex-col md:flex-row justify-between items-center md:py-4">
        <div
          className="text-sm text-center mb-2 mt-5 md:mt-0 md:mb-0 md:text-base lg:text-lg"
          lang="en">
          Digital Interior Designs by
        </div>

        <LogoColor width="225" className="block" />

        <TenYearsDesign className="hidden md:block md:text-base lg:text-lg" />
      </div>
    </header>
  )
}

function Footer({ className }) {
  return (
    <footer
      className={classNames(
        className,
        'footer bg-black text-white p-2 text-center relative md:min-h-[6rem] md:flex flex-row flex-nowrap items-center'
      )}>
      <div className="container md:flex-1 md:w-full">
        <div className="w-full md:flex flex-row flex-nowrap items-center">
          <div className="social flex flex-row flex-nowrap justify-center items-center my-5 md:my-6 md:order-2 md:ml-8">
            <div className="hidden lg:inline-block mr-4">Последвай ни:</div>

            <a
              className="mx-4 md:mx-2"
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.facebook.com/Digital-Interior-Designs-110337531451919">
              <FacebookIcon className="w-7 h-7 md:w-5 md:h-5" />
            </a>

            <a
              className="mx-4 md:mx-2"
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.instagram.com/digital.interiors/">
              <InstagramIcon className="w-7 h-7 md:w-5 md:h-5" />
            </a>
          </div>

          <div className="flex-grow-0 flex-shrink-0">
            <LogoWhite className="inline-block" width={182} />
          </div>

          <div className="md:order-3 md:ml-auto">
            <div className="copy text-xs my-4" lang="en">
              Copyright © ALL IN Studio {new Date().getFullYear()}
              <span className="hidden md:inline"> | </span>
              <br className="md:hidden" />
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://ralev.com">
                Web design
              </a>{' '}
              by{' '}
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://ralev.com">
                Ralev.com
              </a>
            </div>
          </div>
        </div>

        <GoToTop className="text-center md:fixed md:z-50 md:right-0 md:bottom-[-1px] md:mb-24" />
      </div>
    </footer>
  )
}

const ContainerMd = styled.div`
  @media (max-width: ${parseInt(theme('screens.md'), 10) - 1}px) {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
`

export default function IndexPage({
  data: {
    cmsPage: data,
    site: { siteMetadata: meta },
  },
  ...props
}) {
  const { meta: userMeta } = data
  const [inquiryVisiblity, setInquiryVisibility] = useState(true)
  const { breakpoint } = useBreakpoints()

  const decorImage = useMemo(() => {
    switch (breakpoint) {
      case 'lg':
      case 'xl':
      case '2xl':
        return (
          <StaticImage
            className="hero-gatsby-static-object-fit"
            src="../images/bg-hero-decor.png"
            alt=""
            // layout="fixed"
            placeholder="none"
            loading="eager"
            objectFit={true}
            imgClassName="z-0"
            width={587}
          />
        )
      default:
        return (
          <StaticImage
            className="hero-gatsby-static-object-fit"
            src="../images/bg-hero-decor.png"
            alt=""
            // layout="fixed"
            placeholder="none"
            loading="eager"
            objectFit={true}
            imgClassName="z-0"
            width={490}
          />
        )
    }
  }, [breakpoint])

  return (
    <>
      <Helmet
        title={userMeta?.title || meta?.title || 'Digital Interior Designs'}
        htmlAttributes={{ lang: meta?.lang || 'en' }}>
        {meta?.description && (
          <meta
            name="description"
            content={userMeta?.description?.remark?.excerpt || meta.description}
          />
        )}
      </Helmet>
      {userMeta?.ogimage?.sharp?.data && (
        <Helmet>
          <meta
            property="og:image"
            content={`${meta.siteUrl}${getSrc(userMeta.ogimage.sharp.data)}`}
          />
          <meta
            property="og:image:width"
            content={userMeta.ogimage.sharp.data.width}
          />
          <meta
            property="og:image:height"
            content={userMeta.ogimage.sharp.data.height}
          />
        </Helmet>
      )}
      <div className="page relative">
        <Header className="absolute lg:relative w-full z-10" />

        <main className="mb-8 mt-0">
          <section className="intro-section min-h-screen md:min-h-0 md:mb-40 flex flex-col items-stretch md:block">
            <div className="relative text-center pt-28 md:pt-10 lg:pt-0 md:static">
              <ContainerMd className="container md:relative">
                <div className="section-decor select-none absolute w-1/2 h-full left-0 top-0 md:static md:w-auto md:h-auto">
                  {decorImage}
                </div>
              </ContainerMd>

              <div className="relative mx-4 z-[1] md:mx-0 md:z-[150] md:text-left">
                <ContainerMd className="container relative md:overflow-hidden">
                  <h1
                    className="text-4xl leading-snug font-black md:font-bold md:leading-none md:mt-10 lg:text-[65px]"
                    dangerouslySetInnerHTML={{ __html: data.hero.heading }}
                  />

                  <Button
                    className="align-bottom -mb-6 md:mb-0"
                    onClick={() => setInquiryVisibility(true)}>
                    {data.hero.buttonLabel}
                  </Button>
                </ContainerMd>
              </div>
            </div>

            <ContainerMd className="container relative flex-grow min-h-0">
              <div className="lg:z-0 md:-mt-16 md:ml-96 lg:ml-[328px]">
                <GatsbyImage
                  className="!static md:!relative"
                  imgClassName=""
                  image={getImage(data.hero.image)}
                  loading="eager"
                  alt="Digital Interior Design by All in Studio"
                />
              </div>
            </ContainerMd>

            <TenYearsDesign className="lg:hidden text-center text-sm py-4" />
          </section>

          <WhatIsSection className="md:mb-40" {...data.whatIs} />

          {data.video?.videoUrl && (
            <VideoSection className="my-10 md:mb-40" {...data.video} />
          )}

          <ForWhomSection className="md:mb-40" {...data.forWhom} />

          <VisualizationsSection
            className="md:mb-36"
            {...data.visualizations}
          />

          <HowItWorksSection
            {...data.how}
            className="mb-20 md:mb-40"
            ctaOnClick={() => setInquiryVisibility(true)}
          />

          <ProductSection {...data.product} />

          <PortfolioSection className="md:mb-40" {...data.portfolio} />

          <AwardsSection {...data.awards} className="mb-20 md:mb-40" />

          <CTASection
            {...data.cta}
            className="mb-20 md:mb-40"
            ctaOnClick={() => setInquiryVisibility(true)}
          />

          <FAQSection className="mb-20" {...data.faq} />

          <Newsletter {...data.mailchimp} />
        </main>

        <Overlay
          visible={inquiryVisiblity}
          onClose={() => setInquiryVisibility(false)}
          className="flex flex-row justify-center items-center">
          <motion.div className="w-full md:w-3/4 p-4 md:p-14 bg-white lg:w-[768px] max-h-full overflow-auto">
            <div className="md:pl-20 md:pr-16 relative pt-8 md:pt-0">
              <EnquiryIcon className="absolute hidden md:block left-0 top-0 w-12 h-12" />

              <EnqueryForm
                onBackButtonClick={() => setInquiryVisibility(false)}
              />

              <button
                className="absolute block p-2 focus:outline-none top-0 right-0 md:top-auto md:right-auto md:bottom-full md:left-full md:ml-2 md:mb-2"
                onClick={() => setInquiryVisibility(false)}>
                <CloseIcon className="block fill-current w-4 h-4" />
              </button>
            </div>
          </motion.div>
        </Overlay>

        <Footer />
      </div>
    </>
  )
}

export const IndexPageQuery = graphql`
  query IndexPageQuery($id: String!) {
    site {
      siteMetadata {
        title
        description
        siteUrl
        lang
      }
    }
    cmsPage(id: { eq: $id }) {
      ... on PageIndex {
        meta {
          title
          description {
            id
            remark: childMarkdownRemark {
              excerpt(pruneLength: 160)
            }
          }
          ogimage {
            sharp: childImageSharp {
              data: gatsbyImageData(
                width: 1200
                height: 1200
                quality: 100
                layout: FIXED
                formats: AUTO
                outputPixelDensities: [1]
                breakpoints: []
                transformOptions: { cropFocus: CENTER }
              )
            }
          }
        }
        hero {
          heading
          buttonLabel
          image {
            childImageSharp {
              gatsbyImageData(width: 780, placeholder: BLURRED)
            }
          }
        }

        video {
          videoUrl
          cover {
            publicURL
          }
        }

        whatIs {
          heading
          content {
            remark: childMarkdownRemark {
              html
            }
          }
        }
        forWhom {
          heading
          contentLeft {
            remark: childMarkdownRemark {
              html
            }
          }
          contentRight {
            remark: childMarkdownRemark {
              html
            }
          }
        }
        visualizations {
          heading
          slider {
            id
            childImageSharp {
              gatsbyImageData(width: 558)
            }
          }
        }
        how {
          heading
          buttonText
          steps {
            name
            image {
              childImageSharp {
                gatsbyImageData(width: 343, aspectRatio: 1)
              }
            }
          }
        }
        product {
          heading
          icons {
            drawings
            visualizations
            estimate
            deals
          }
          afterIcons {
            remark: childMarkdownRemark {
              html
            }
          }
        }
        portfolio {
          heading
          slider {
            childImageSharp {
              gatsbyImageData(width: 558)
            }
          }
        }
        awards {
          heading
          logos {
            id
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                placeholder: NONE
                aspectRatio: 1.66
                backgroundColor: "#FFFFFF"
                transformOptions: { fit: CONTAIN }
              )
            }
          }
        }
        cta {
          heading
          bgImage {
            childImageSharp {
              gatsbyImageData(width: 1920, layout: FULL_WIDTH)
            }
          }
        }
        faq {
          heading
          qna {
            question
            answer {
              remark: childMarkdownRemark {
                html
              }
            }
          }
          afterQna {
            remark: childMarkdownRemark {
              html
            }
          }
        }
        mailchimp {
          formHtml
        }
      }
    }
  }
`
