import classNames from 'classnames'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React from 'react'

function Awards({ className, heading, logos, ...props }) {
  return (
    <section className={classNames('section section-awards', className)}>
      <div className="container">
        {heading && <h2 className="text-center mb-16" dangerouslySetInnerHTML={{ __html: heading }} />}

        {logos.length && (
          <div className="flex flex-row flex-wrap md:flex-nowrap md:mb-40 -mx-2 md:mx-0">
            {logos.map((logo) => (
              <div key={logo.id} className="px-2 md:px-4 my-3 md:my-0 w-1/3 md:w-auto md:flex-1">
                <div className="h-full relative">
                  <GatsbyImage
                    className="!static"
                    image={getImage(logo)}
                    alt=""
                    objectFit="contain"
                  />
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </section>
  )
}

export default Awards
